export const director_data = [
    {
      section_title: "Results from Director Survey",
      charts: [
        {
            "id": 0,
            "labels" : ["The general budget of my museum","An endowment for a named position","Another form of endowment","A municipality","A university","Other"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.56,  0.23,  0.02,  0.00,  0.19,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.50,  0.25,  0.25,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.53,  0.19,  0.03,  0.00,  0.25,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.18,  0.09,  0.00,  0.00,  0.73,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.63,  0.37,  0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.75,  0.25,  0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.75,  0.25,  0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.50,  0.40,  0.10,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.71,  0.00,  0.00,  0.00,  0.29,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.60,  0.40,  0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.25,  0.50,  0.00,  0.00,  0.25,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.64,  0.36,  0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.33,  0.00,  0.00,  0.00,  0.67,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.73,  0.18,  0.00,  0.00,  0.09,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.29,  0.43,  0.00,  0.00,  0.29,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.62,  0.00,  0.00,  0.00,  0.38,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.67,  0.33,  0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.33,  0.33,  0.00,  0.00,  0.33,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.63,  0.38,  0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [1.00,  0.00,  0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.40,  0.40,  0.20,  0.00,  0.00,  0.00]
              },
            ],
            "questionName": "Through what group or fund is your salary as a museum director predominantly (or wholly) paid?",
            "graphType": "column",
            "questionType": "radiogroup"
        },
        {
            "id": 1,
            "labels" : ["Mean Percentage"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [58.12]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [62.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [59.41]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [60.55]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [56.16]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [68.25]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [53.88]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [61.50]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [57.43]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [53.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [63.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [54.36]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [61.17]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [54.27]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [55.14]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [57.15]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [54.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [66.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [54.63]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [62.50]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [64.40]
              },
            ],
            "questionName": "In your last complete fiscal year, approximately what proportion (%) of your annual budget was allocated to staff compensation and benefits? (Please exclude any non-numeric characters)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 2,
            "labels" : ["Mean Percentage"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [11.21]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [7.25]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [9.84]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [6.73]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [15.26]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [7.25]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [12.63]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [11.20]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [16.14]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [10.40]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [9.75]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [15.18]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [6.33]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [11.64]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [8.43]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [14.08]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [7.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [11.67]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [9.13]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [15.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [10.20]
              },
            ],
            "questionName": "For your last complete fiscal year, approximately what proportion (%) of your organization’s revenue was directly contributed by board members? (Please exclude any non-numeric characters)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 3,
            "labels" : ["Yes","No"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.70,  0.30]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [1.00,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.69,  0.31]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.36,  0.64]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.63,  0.37]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [1.00,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [1.00,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.80,  0.20]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.57,  0.43]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.60,  0.40]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.75,  0.25]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.73,  0.27]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.50,  0.50]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.82,  0.18]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.57,  0.43]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.62,  0.38]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.67,  0.33]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67,  0.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.75,  0.25]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [1.00,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.80,  0.20]
              },
            ],
            "questionName": "Do you know the gender, race, and ethnicity of each of your board members?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 4,
            "labels" : ["Woman","Man","Non-binary/genderqueer/third gender","Another Gender"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.54,  0.45,  0.01,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.51,  0.48,  0.01,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.53,  0.45,  0.01,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.69,  0.31,  0.00,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.53,  0.45,  0.02,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.56,  0.43,  0.01,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.50,  0.49,  0.01,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.52,  0.45,  0.04,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.53,  0.47,  0.00,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.48,  0.50,  0.03,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.53,  0.47,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.55,  0.44,  0.01,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.71,  0.29,  0.00,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.54,  0.46,  0.00,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.64,  0.34,  0.02,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.57,  0.43,  0.00,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.47,  0.44,  0.09,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.46,  0.54,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.48,  0.51,  0.01,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.48,  0.52,  0.00,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.57,  0.42,  0.01,  0.00]
              },
            ],
            "questionName": "Keeping in mind just your board members who are on the Finance and/or Executive committees, how many members of each gender do you have? Please mark 0 where applicable in the categories below.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 5,
            "labels" : ["Yes","No"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.26,  0.74]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.31,  0.69]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [1.00,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.05,  0.95]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.10,  0.90]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.29,  0.71]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.25,  0.75]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.09,  0.91]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.83,  0.17]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.18,  0.82]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.57,  0.43]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.38,  0.62]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.33,  0.67]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.13,  0.88]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
            ],
            "questionName": "Is your museum part of a university or college?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 5,
            "labels" : ["Independent Governance Board","Same Board as the University"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.09,  0.91]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.00,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.10,  0.90]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.09,  0.91]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.20,  0.80]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.20,  0.80]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00,  1.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.00,  0.00]
              },
            ],
            "questionName": "What’s the governance structure of your museum?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
        ]
      },
    ]